<template>
  <div class="MyAccountOverviewView">
    <div class="md-layout">
      <div class="md-layout md-layout-item md-xlarge-size-75 md-large-size-75 md-medium-size-75 md-small-size-100 md-xsmall-size-100">
        <div class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
             v-for="item in this.cardButtonItems"
             :key="item.id">
          <CardButton :route="item.route" :icon="item.icon" :text="item.text" :description="item.description" />
        </div>
      </div>
      <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100">
        <CardNavList :title="cardNavList.title" :description="cardNavList.description" :items="cardNavList.items" />
      </div>
    </div>
  </div> <!-- /.MyAccountOverviewView -->
</template>

<script>
import CardButton from '@/components/util/CardButton';
import CardNavList from '@/components/util/CardNavList';

export default {
  name: 'MyAccountOverviewView',
  components: {
    CardButton,
    CardNavList
  },
  data() {
    return {
      cardButtonItems: [
        {
          id: 0,
          route: '/my-account/user-details',
          icon: 'account_box',
          text: this.$t('myAccountView.accountDetails'),
          description: this.$t('myAccountOverviewView.editUserDetails')
        },
        {
          id: 1,
          route: '/my-account/billing-details',
          icon: 'account_balance_wallet',
          text: this.$t('myAccountView.billingDetails'),
          description: this.$t('myAccountOverviewView.editBillingDetails')
        },
        {
          id: 2,
          route: '/my-account/user-auctions',
          icon: 'gavel',
          text: this.$t('myAccountView.myAuctions'),
          description: this.$t('myAccountOverviewView.activeAuctionsLatestBids')
        },
        {
          id: 3,
          route: '/my-account/user-domains',
          icon: 'domain_verification',
          text: this.$t('myAccountView.myDomains'),
          description: this.$t('myAccountOverviewView.changeDnsReviewDomain')
        },
        {
          id: 4,
          route: '/my-account/account-security',
          icon: 'security',
          text: this.$t('myAccountView.accountSecurity'),
          description: this.$t('myAccountOverviewView.reviewAdjustSecurity')
        }
      ],
      cardNavList: {
        title: this.$t('myAccountCardNavList.quickAccess'),
        description: this.$t('myAccountCardNavList.frequentlyAccessedSettings'),
        items: [
          {
            to: '/my-account/user-details',
            text: this.$t('myAccountCardNavList.addEditUserDetails')
          },
          {
            to: '/my-account/user-details',
            text: this.$t('myAccountCardNavList.chooseDefaultDetails')
          },
          {
            to: '/my-account/billing-details',
            text: this.$t('myAccountCardNavList.addEditBillingDetails')
          },
          {
            to: '/my-account/user-auctions',
            text: this.$t('myAccountCardNavList.showActiveAuctions')
          },
          {
            to: '/my-account/user-domains',
            text: this.$t('myAccountCardNavList.manageNameservers')
          },
          {
            to: '/my-account/user-domains',
            text: this.$t('myAccountCardNavList.goToDomains')
          },
          {
            to: '/my-account/user-details',
            text: this.$t('myAccountCardNavList.phoneValidation')
          },
          {
            to: '/my-account/user-details',
            text: this.$t('myAccountCardNavList.changeDefaultLanguage')
          },
        ]
      }
    }
  }
};
</script>
